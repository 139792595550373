import React from 'react';

const Hero: React.FC = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-[150px] py-10 bg-gradient-to-b from-blue-500 to-indigo-700 text-white">
      <h1 className="text-5xl font-bold mb-4">Find music that makes you dance</h1>
      <p className="text-lg mb-8">Your one-stop platform for curated and rated dance music</p>
    </div>
  );
};

export default Hero;
