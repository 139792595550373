// src/MaintenancePage.tsx
import React from 'react';

const MaintenancePage: React.FC = () => {
    return (
        <div style={{ textAlign: 'center', padding: '50px', fontFamily: 'Arial, sans-serif' }}>
            <h1 style={{ fontSize: '50px', color: '#333' }}>We’ll be back soon!</h1>
            <p style={{ fontSize: '20px', color: '#666' }}>
                Our website is currently undergoing maintenance. Thank you for your patience.
            </p>
        </div>
    );
};


export default MaintenancePage;
