import React from 'react';
import Home from './components/Home'; // Adjust the path as needed
import MaintenancePage from './components/MaintenancePage'; 

const isMaintenanceMode = process.env.REACT_APP_MAINTENANCE_MODE === 'true';

const App: React.FC = () => {
  return isMaintenanceMode ? 
  <MaintenancePage/> :
  (
    <div>
      <Home />
    </div>
  );
};

export default App;
